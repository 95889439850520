<template>
  <b-row>
    <b-col sm="12" md="6">

      <dl class="row">
        <dt class="col-sm-3">Kode</dt>
        <dt class="col-sm-9">{{data.code}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Nama</dt>
        <dt class="col-sm-9">{{data.name}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Alamat</dt>
        <dt class="col-sm-9">{{data.address}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Kota</dt>
        <dt class="col-sm-9">{{data.city}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Telepon</dt>
        <dt class="col-sm-9">{{data.telp}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Email</dt>
        <dt class="col-sm-9">{{data.email}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Website</dt>
        <dt class="col-sm-9">{{data.website}}</dt>
      </dl>

    </b-col>
    <b-col sm="12" md="6">

      <dl class="row">
        <dt class="col-sm-3">Bank Rek 1</dt>
        <dt class="col-sm-9">{{data.rek1_name}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Bank Rek 2</dt>
        <dt class="col-sm-9">{{data.rek2_name}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Is Pusat</dt>
        <dt class="col-sm-9">{{data.is_main ? 'Ya' : 'Tidak'}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Created At</dt>
        <dt class="col-sm-9">{{data.createdAt | fulldate}}</dt>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">Updated At</dt>
        <dt class="col-sm-9">{{data.updatedAt | fulldate}}</dt>
      </dl>

    </b-col>
  </b-row>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data(){
    return {
      data:{}
    }
  },
  methods:{
    ...mapActions({
      dispatchShow: 'companies/show'
    }),
    async getData(){
      try {
        const id = this.$route.params.id
        const data = await this.dispatchShow(id)
        this.data = data
      } catch (error) {
        this.handleError(error)        
      }
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>